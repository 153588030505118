// REACT IMPORTS
import React from "react";
import ReactDOM from "react-dom";
// Preact imports
import Name from "./Views/Name";
import Footer from "./Views/Footer";

// CSS IMPORT
import "./../sass/main.scss";

ReactDOM.render(<Name />, document.querySelector(".main--grid"));
ReactDOM.render(<Footer />, document.querySelector(".footer--grid"));


