import React from 'react';
import mcMarket from './../../img/mc-market.svg';
import discord from './../../img/discord-brands.svg';

const config = require('./../../config.json');
export default class Footer extends React.Component {
	render() {
		return (
			<div className="footer">
				<div className="socials">
					<ul className="list">
						<li className="list__header">{config.listOne.title}</li>
						<a
							href=""
							onClick={() => window.alert('Hey there! Feel free to contact me on Discord @ Aidan#0003.')}
						>
							<li className="list__item">
								<img src={discord} width="20px" height="20px" alt="Discord" />
								{config.listOne.links[0].text}
							</li>
						</a>
					</ul>
				</div>
				<div className="enquiries">
					<ul className="list">
						<li className="list__header">Enquiries</li>
						<li className="list__item">
							Contact me through e-mail
							<br />
							<ins>
								<a href={`mailto:${config.emailAddress}`}> here</a>
							</ins>{' '}
							for business enquiries.
						</li>
					</ul>
				</div>
				<div className="links">
					<ul className="list">
						<li className="list__header">{config.listTwo.title}</li>
						<a href={config.listTwo.links[0].url} target="_blank">
							<li className="list__item">{config.listTwo.links[0].text}</li>
						</a>
						<a href={config.listTwo.links[1].url} target="_blank">
							<li className="list__item">{config.listTwo.links[1].text}</li>
						</a>
					</ul>
				</div>
				<div className="copy">
					<p className="credentials">
						&copy; All Rights Reserved.{' '}
						<ins>
							<a href="/privacy.html">Privacy Policy.</a>
						</ins>
					</p>
				</div>
			</div>
		);
	}
}
