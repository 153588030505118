import React from 'react';

import mobileWave from './../../img/mobile_wave.svg';

const config = require('./../../config.json');

export default class Name extends React.Component {
  render() {
    return (
      <div className='header'>
        <h1 className='text__primary'>{config.name}</h1>
        <h2 className='text__secondary'>{config.description}</h2>
        <h3 className='text__mobile'>
          <ins>Scroll to links below.</ins>
        </h3>
        <img
          src={mobileWave}
          alt='Mobile Wave'
          alt='mobile wave'
          className='footer__mobile-img'
        />
      </div>
    );
  }
}
